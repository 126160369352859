<template>
  <div class="top3">
    <div class="main-title">热门专业</div>
    <div class="driver"></div>
    <div>
      <el-carousel :interval="4000" indicator-position="outside" height="27vw">
        <el-carousel-item indicator-position="none" v-for="item in hotSubjects" :key="item.id">
          <div style="display: flex;">
            <div style="width: 25vw;margin-left: 1.2vw;cursor: pointer" v-for="itemSubject of item.subjects" :key="itemSubject.id" @click="toIntroduce(itemSubject)">
              <div style="padding: 0 3vw 3vh;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
border-radius: 10px;">
                <div style="padding-top: 1vh">
                  <img width="100%" height="100%" src="../../assets/myImage/zhuanye.png" alt="">
                </div>
                <div style="margin-top: 3vh;padding-left: 1vw">
                  <h4>{{ itemSubject.name_chs }}</h4>
                  <p>{{ itemSubject.subject_level.name}}</p>
                </div>
              </div>
<!--              <majorCard></majorCard>-->
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import {schoolService} from "@/services/school";
import majorCard from "@/components/majorCard/majorCard";
export default {
  name: "top3Skill",
  components:{
    majorCard
  },
  data(){
    return{
      hotSubjects:[
        {id:1,subjects:[]},
        {id:2,subjects:[]},
        {id:3,subjects:[]},
      ]
    }
  },
  created() {
    schoolService.getAllSubjects('?highlight=true').then((data) => {
      this.hotSubjects[0].subjects = data.results.slice(0,3)
      this.hotSubjects[1].subjects = data.results.slice(3,6)
      this.hotSubjects[2].subjects = data.results.slice(6,9)
    })
  },
  methods:{
    toIntroduce(item){
      this.$router.push({path:'/majorIntroduce',query:{item:JSON.stringify(item)}})
    },
  }
}
</script>

<style lang="scss" scoped>
// *{
//  outline: 1px solid red;
//}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 40vh;
  margin: 0;

}

.el-carousel__item:nth-child(2n) {


}

.el-carousel__item:nth-child(2n+1) {


}
.top3{
  width: 80%;
  margin: 0 auto;
}
.main-title{
  font-size: 35px;
  text-align: center;
  margin-top: 40px;
}
.driver{
  height: 4px;
  text-align: center;
  width: 50px;
  background-color:#007DD1 ;
  margin: 20px auto;
}

.client-feedback-wrapper{
  height: 70vh;
}
.slide{
  height: 65vh;
  position: relative;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  span{
    position: absolute;
    top: 85%;
    left: 80%;
  }
  @media screen and (max-width: 700px) {
    span{
      position: absolute;
      top: 87%;
      left: 80%;
    }
  }
}
@media screen and (max-width: 700px) {
  .slide{
    height: 65vh;
  }
}
.swiper-pagination{
  //margin-top: 40vh;
  margin-bottom: -5vh;
}
</style>

<template>
  <!-- <div class="service-grid-item__image">
            <div class="service-grid-item__image-wrapper">
                <router-link to="/project-details">
                    <img :src="project.image" class="img-fluid" alt="thumbnail">
                </router-link>
            </div>
        </div> -->
  <div class="service-grid-item__content service-grid-item--style2">
    <h6 class="title">
      {{ subject.name_chs }}
    </h6>
    <p class="subtitle">{{ subject.name_eng }}</p>
  </div>
  <!-- <div class="accordion" role="tablist">
      <div v-for="(type, i) in schoolSubjects" :key="i">
        <b-card
          no-body
          class="mb-1"
          v-if="schoolSubjects[i].subjects.length != 0"
        >
          <b-card-header header-tag="header" class="p-1" role="tab">
            <b-button block v-b-toggle="'accordion-' + i" variant="info">{{
              type.type
            }}</b-button>
          </b-card-header>
          <b-collapse
            :id="'accordion-'+i"
            accordion="my-accordion"
            role="tabpanel"
          >
            <b-card-body>
              <ul class="sidebar-list">
                <li
                  v-for="(subject, id) in schoolSubjects[i].subjects"
                  :key="id"
                >
                  <router-link to="#">
                    {{ subject.name_chs }}</router-link>
                </li>
              </ul>
            </b-card-body>
          </b-collapse>
        </b-card>
      </div>
    </div> -->
</template>

<script>
export default {
  props: ["subject"],
};
</script>

<style lang="scss">
</style>
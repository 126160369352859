<template>
  <div>
    <div class="subMajors">
      <h3 @click="$router.back()"> < 返回 </h3>
      <div class="main-title">{{ $route.query.main }}</div>
      <div class="driver"></div>
      <div class="content">
        <majorCard class="majorCard" v-for="item of subMajors.results"
                   :key="item.id"
                   :img1="item.school.school_logo"
                   :img2="img2"
                   :zhuanye="item.name_chs"
                   :school="item.school.name_chs"
                   :level="item.subject_level.name"
                   :time="item.subject_duration"
                   :money="item.subject_fee"
        ></majorCard>
      </div>
      <div class="myBtn" @click="getNext">查看更多</div>
    </div>
  </div>
</template>

<script>
import majorCard from "@/components/majorCard/majorCard";
import {constants} from "@/services/constants";
import {httpService} from "@/services/http";
import img2 from '../assets/myImage/college3.png'

export default {
  name: "subMajors",
  components:{
    majorCard
  },
  data(){
    return{
      subMajors:'',
      img2,
      next:''
    }
  },
  created() {//根据父元素参数来获取不同的subject数据
    httpService.get(constants.SUBJECT_LIST + `?subject_type_sub__name=${this.$route.query.main}&subject_type_main__name=${this.$route.query.main0?this.$route.query.main0:''}`).then((data) => {
      this.subMajors = data.body
      console.log(data)
      this.next = data.body.next;
    })
  },
  methods:{
    getNext(){//查看更多
      if (this.subMajors.results.length < this.subMajors.count){//加入判断，如果展示完了所有院校，那么就不再发送请求
        httpService.get(this.next).then((data) => {
          this.subMajors.results = this.subMajors.results.concat(data.body.results)
          this.next = data.body.next
        })
      }else {
        alert('没有更多专业了～～～')
      }
    },
  }
}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.subMajors{
  width: 80%;
  margin: 5vh auto;
  h3{
    cursor: pointer;
  }
  .content{
    display:flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    .majorCard{
      margin-top: 3vh;
      width: 20vw;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
<template>
  <div>
    <div class="smallAge">
      <div>
        <img width="100%" src="../../public/assets/img/tou.jpeg" alt="">
      </div>
      <div class="point">
          <div>
            <a href="#tenReason">留学优势</a>
          </div>
          <div>
            <a href="#tixi">新加坡小龄留学教育体系</a>
          </div>
          <div>
            <a href="#youeryuan">新加坡幼儿园</a>
          </div>
          <div>
            <a href="#xiaoxue">新加坡小学</a>
          </div>
          <div>
            <a href="#zhongxue">新加坡中学</a>
          </div>
          <div>
            <a href="#peidu">母亲陪读、全家移民</a>
          </div>
          <div>
            <a href="#guwen">顾问老师</a>
          </div>
          <div>
            <a href="#contact">联系我们</a>
          </div>
        </div>
      <div class="introduction">
        <div class="introduction-left">
          <p class="myH">导语</p>
        </div>
        <div class="introduction-right">
          <p>
            小龄留学，是指3-18岁的学生出国留学，入读的学历阶段包括幼儿园、小学、中学。目前接受小龄留学国家很少。新加坡
            以其良好的治安、宜人的环境以及闻名世界的教育吸引着众多小龄留学生。
          </p>
        </div>
      </div>
      <div id="tenReason" class="tenReason1">
        <div class="myTitle">
          <img src="../../public/assets/img/书本.png" alt="">
          <div>
            <p class="myH">低龄留学，选择新加坡的十大理由</p>
            <hr>
          </div>
        </div>
        <div class="myContent">
          <div class="allReason">
            <div class="reason" v-for="item in tenReason" :key="item.id">
              <p style="font-weight: bold;font-size: 1vw;margin-bottom: 7px">{{item.id}}&nbsp;{{item.title}}</p>
              <p style="font-weight: bold;font-size: .8vw;margin-bottom: 7px">{{item.text}}</p>
            </div>
          </div>
        </div>
      </div>
      <div id="tixi" class="tenReason" style="background-color: #eff4f8">
        <div class="myTitle">
          <img src="../../public/assets/img/书本.png" alt="">
          <div>
            <p class="myH">新加坡小龄教育体系</p>
            <hr>
          </div>
        </div>
        <div class="myContent">
          <div>
            <img width="100%" src="../../public/assets/img/jiegou.jpg" alt="">
          </div>
        </div>
      </div>
      <div id="youeryuan" class="tenReason">
        <div class="myTitle">
          <img src="../../public/assets/img/书本.png" alt="">
          <div>
            <p class="myH">新加坡幼儿园，教学独特，入学灵活</p>
            <hr>
          </div>
        </div>
        <div class="yI">
          <div class="yI-left">
            <div class="in">
              <div>
                <div class="text">1. 学制：三年 申请年龄：3-6 费用：公立幼儿园为800-1200新币/月</div>
              </div>

              <div>
                <div class="text">2. 简介：新加坡的幼儿园为3-6岁的孩童提供三年系统的学前教育</div>
              </div>

              <div>
                <div class="text">3. 性质：新加坡幼儿园多数为公立监管，私立运营</div>
              </div>

              <div>
                <div class="text">4. 教学语言：幼儿园常以英文和中文进行双语教学</div>
              </div>
            </div>
            <div class="in">
              <div>
                <div class="text">5. 上课时段：根据家长和孩子的需求，有半天班和全天班可选择</div>
              </div>
              <div>
                <div class="text">6. 报名时间：如有学额，全年可任何时间入学</div>
              </div>

              <div>
                <div class="text">7. 费用：公立和私立幼儿园每月学费800-3000新币不等</div>
              </div>

              <div>
                <div class="text">8. 申请流程：3-6岁申请，随时可入学</div>
              </div>
            </div>
          </div>
          <div class="yI-right">
            <div class="yI-right-img">
              <img height="100%" width="100%" src="../../public/assets/img/peidu3.png" alt="">
            </div>
          </div>
        </div>

        <div class="myContent">
          <p class="tuijian">推荐院校</p>
          <div style="display: flex;justify-content: space-between">
            <schoolCard
                style="background:white;border-radius: 20px;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);"
                v-for="item of kindergarten" :key="item.id"
                :type="item.school_types"
                :img="item.school_logo"
                :title="item.name_chs"
                :subTitle="item.name_eng"
                :message="item.sub_title"
                :rank="item.rank"
                @click.native="toIntroduce(item)"
            ></schoolCard>
          </div>
        </div>
      </div>
      <div id="xiaoxue" class="tenReason">
        <div class="myTitle">
          <img src="../../public/assets/img/书本.png" alt="">
          <div>
            <p class="myH">新加坡小学，免试入学优势多</p>
            <hr>
          </div>
        </div>
        <div class="xuezhi">
          新加坡小学学制为6年，申请年龄需要6岁以上，学费为825新币/月
        </div>
        <div class="yI2">
          <div class="myB">
            <div :class="[btn1,{'myClick':isClick}]" @click="isClick=true,isClick2=false,liucheng=true">免试入学</div>
            <div :class="[btn1,{'myClick':isClick2}]" @click="isClick=false,isClick2=true,liucheng=false">考试入学</div>
          </div>
          <div v-if="liucheng" class="liucheng">
            <div class="liucheng-son">
              <div>免试优势</div>
              <div class="sonT">无过渡期，无需考试，母系亲属可陪读</div>
            </div>
            <div class="liucheng-son">
              <div>申请年龄</div>
              <div class="sonT">6周岁可申请直接入小学一年级</div>
            </div>
            <div class="liucheng-son">
              <div>申请流程</div>
              <div class="sonT">八月底之前提交申请次年一月开学</div>
            </div>
            <div class="liucheng-son">
              <div>申请费用</div>
              <div class="sonT">行政手续费+报名费+境外服务费</div>
            </div>
          </div>
          <div v-else class="liucheng">
            <div class="liucheng-son">
              <div>考试优势</div>
              <div class="sonT">通过了考试的申请人可获得一个适合自己的政府学校名额</div>
            </div>
            <div class="liucheng-son">
              <div>针对人群</div>
              <div class="sonT">希望在第二年一月如新加坡政府中小学的外国学生</div>
            </div>
            <div class="liucheng-son">
              <div>考试内容</div>
              <div class="sonT">考试内容包括英语和数学</div>
            </div>
            <div class="liucheng-son">
              <div>考试费用</div>
              <div class="sonT">考试费用为672新币</div>
            </div>
          </div>
        </div>
        <div class="myContent">
          <p class="tuijian">推荐院校</p>
          <div style="display: flex;justify-content: space-between">
            <schoolCard
                style="border-radius: 20px;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);background-color: white"
                v-for="item of middle" :key="item.id"
                :type="item.school_types"
                :img="item.school_logo"
                :title="item.name_chs"
                :subTitle="item.name_eng"
                :message="item.sub_title"
                :rank="item.rank"
                @click.native="toIntroduce(item)"
            ></schoolCard>
          </div>
        </div>
      </div>
      <div id="zhongxue" class="tenReason">
        <div class="myTitle">
          <img src="../../public/assets/img/书本.png" alt="">
          <div>
            <p class="myH">新加坡中学，班型丰富选择多</p>
            <hr>
          </div>
        </div>
        <div class="xuezhi">
          新加坡公立中学教育提供不同的课程选择，如快捷班、特别班、普通课程等。学制为4-5年
        </div>
        <div class="yI3">
          <div class="zhong">
            <div class="zhong-son">
              <div class="zhongB">快捷班</div>
              <div class="zhongT">
                <ul>
                  <li>学制：四年</li>
                  <li>主修科目：英文、母语、数学、体育、公民与道德教育等</li>
                  <li>毕业考试：学生须参加“O”level考试决定升学</li>
                </ul>
              </div>
            </div>

            <div class="zhong-son">
              <div class="zhongB">特别班</div>
              <div class="zhongT">
                <ul>
                  <li>学制：四年</li>
                  <li>招收对象：成绩优秀的学生</li>
                  <li>就读情况：每年只有5%的小学毕业生就读</li>
                  <li>主修科目：高级母语</li>
                  <li>毕业考试：学生须参加“O”level考试决定升学</li>
                </ul>
              </div>
            </div>

            <div class="zhong-son">
              <div class="zhongB">普通课程</div>
              <div class="zhongT">
                <ul>
                  <li>学制：五年</li>
                  <li>主修科目：学术和工艺</li>
                  <li>毕业考试：普通课程班须参加新加坡剑桥初级水准“N”的考试。
                    成绩优异可继续上第五年的中学课程，然后参加“O”level考试</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="myContent">
          <p class="tuijian">推荐院校</p>
          <div style="display: flex;justify-content: space-between">
            <schoolCard
                style="background: white;border-radius: 20px;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);"
                v-for="item of primary" :key="item.id"
                :type="item.school_types"
                :img="item.school_logo"
                :title="item.name_chs"
                :subTitle="item.name_eng"
                :message="item.sub_title"
                :rank="item.rank"
                @click.native="toIntroduce(item)"
            ></schoolCard>
          </div>
        </div>
      </div>
      <div id="peidu" class="tenReason">
        <div class="myTitle">
          <img src="../../public/assets/img/书本.png" alt="">
          <div>
            <p class="myH">母亲陪读，全家移民</p>
            <hr>
          </div>
        </div>
        <div class="myContent">
          <div>
            <div class="peidu">
              <div class="peidu-left">
                <img width="100%" height="100%" src="../../public/assets/img/k12-1.jpg" alt="">
              </div>
              <div class="peidu-right">
                <h3>陪读政策</h3>
                <h4>新加坡政府为了确保学生的学习、生活和安全有保障，给予海外学生母亲贴心的陪读政策，允许学生的母亲、祖母或外祖母到新加坡陪读</h4>
              </div>
            </div>
            <div class="peidu">
              <div class="peidu-left">
                <img width="100%" height="100%" src="../../public/assets/img/k12-2.jpg" alt="">
              </div>
              <div class="peidu-right">
                <h3>政策优势</h3>
                <h4>在陪读的一年后，陪读妈妈们可以申请全职工作，收入可以补贴孩子的学费和在新加坡的生活费</h4>
              </div>
            </div>
            <div class="peidu">
              <div class="peidu-left">
                <img width="100%" height="100%" src="../../public/assets/img/k12-3.jpg" alt="">
              </div>
              <div class="peidu-right">
                <h3>从陪读到全家移民</h3>
                <h4>陪读妈妈在第二年申请到工作准证在新加坡就业后，就可以进一步申请永久居民身份了。孩子在新加坡完成高等教育阶段的学业后，毕业工作满6个月以上即可申请转为永久居民</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="guwen" class="tenReason">
        <div class="myTitle">
          <img src="../../public/assets/img/书本.png" alt="">
          <div>
            <p class="myH">顾问老师</p>
            <hr>
          </div>
        </div>
        <div class="myContent">
          <div>
            <el-carousel :interval="4000" indicator-position="outside" height="28vw">
              <el-carousel-item indicator-position="none" v-for="item in hotSubjects" :key="item.id">
                <div style="display: flex;">
                  <div style="width: 17vw;margin-left: 1.2vw;cursor: pointer" v-for="itemSubject of item.subjects" :key="itemSubject.id">
                    <div style="padding: 0 2vw 1.5vw;box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);border-radius: 10px;height: 100%">
                      <div style="padding-top: .5vw">
                        <img width="100%" height="100%" :src="itemSubject.avatar" alt="">
                      </div>
                      <div style="margin-top: 3vh;padding-left: 1vw">
                        <h4>{{ itemSubject.name}}</h4>
                        <p style="margin: 0"><strong>{{ itemSubject.edu_background1}}：</strong><br>{{ itemSubject.edu_background_school1 }}</p>
                        <p style="margin: .3vw 0 0"><strong>{{ itemSubject.edu_background2}}：</strong><br>{{ itemSubject.edu_background_school2 }}</p>
                      </div>
                    </div>
                    <!--              <majorCard></majorCard>-->
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div id="contact" class="tenReason">
        <div class="myTitle">
          <img src="../../public/assets/img/书本.png" alt="">
          <div>
            <p class="myH">联系我们</p>
            <hr>
          </div>
        </div>
        <div class="myContent">
          <div class="address">
            <div class="addressChild">
              <div class="addressChild-img">
                <img height="100%" src="../../public/assets/img/导航地址.png" alt="">
              </div>
              <div>
                <div>新加坡留学网（上海分公司）</div>
                <div>15952032587</div>
                <div>上海市陆家嘴金融贸易区银城中路68号时代金融中心3302室
                </div>
              </div>
            </div>
            <div class="addressChild">
              <div class="addressChild-img">
                <img height="100%" src="../../public/assets/img/导航地址.png" alt="">
              </div>
              <div>
                <div>智略国际教育（新加坡总部）</div>
                <div>+65 60150667</div>
                <div>1 North Bridge Road #06-01 High Street Centre Singapore 179094</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {httpService} from "@/services/http";
import {constants} from "@/services/constants";
import {schoolService} from "@/services/school";
import schoolCard from "@/components/showSchool/schoolCard";

export default {
  name: "smallAge",
  components:{
    schoolCard
  },
  data(){
    return{
      liucheng:true,
      teacherData:[],
      hotSubjects:[
        {id:1,subjects:[]},
        {id:2,subjects:[]},
        // {id:3,subjects:[]},
      ],
      tenReason:[
        {id:1,title:'双语环境',text:'新加坡拥有英文和中文双语环境，学习生活无障碍'},
        {id:2,title:'陪读政策',text:'新加坡允许16周岁以下学生的母系亲属陪读'},
        {id:3,title:'留学性价比高',text:'相较于欧美国家，新加坡留学的费用更为低廉'},
        {id:4,title:'社会治安好',text:'连续多年被评为全球最安全的国家之一'},
        {id:5,title:'申请世界名校容易',text:'新加坡教育体系与英美教育体系接轨，有“黄金跳板”之称'},
        {id:6,title:'免试入学政策',text:'对于7岁以下的学生，新加坡采取免试入学政策'},
        {id:7,title:'教学质量高',text:'新加坡重视素质教育，倡导学生全面发展，是全球公认教育质量最好的国家之一'},
        {id:8,title:'社会融入性好',text:'华人社会，饮食习惯、文化相近，能很好的适应'},
        {id:9,title:'接受低龄留学',text:'新加坡是亚洲唯一接受低龄留学的国家'},
        {id:10,title:'签证率高',text:'新加坡留学办理流程简单，签证率高'},
      ],
      kindergarten:[],
      middle:[],
      primary:[],
      btn1:'btn1',
      isClick:true,
      isClick2:false,
    }
  },
  created() {
    this.getTeachers()
    this.getKindergarten()
    this.getMiddle()
    this.getPrimary()
  },
  methods:{
    getTeachers(){
      httpService.get(constants.sagepathsAgent).then((data)=>{//获取咨询老师信息
        this.hotSubjects[0].subjects = data.body.results.slice(0,4)
        this.hotSubjects[1].subjects = data.body.results.slice(4,7)
        // this.hotSubjects[2].subjects = data.body.results.slice(6,9)
      })
    },
    getKindergarten(){
      httpService.get(constants.SCHOOL_LIST+50).then((data) => {
        console.log(data.body)
        this.kindergarten.push(data.body)
      })
      httpService.get(constants.SCHOOL_LIST+24).then((data) => {
        this.kindergarten.push(data.body)
      })
      httpService.get(constants.SCHOOL_LIST+50).then((data) => {
        this.kindergarten.push(data.body)
      })
    },
    getMiddle(){
      httpService.get(constants.SCHOOL_LIST+66).then((data) => {
        console.log(data.body)
        this.middle.push(data.body)
      })
      httpService.get(constants.SCHOOL_LIST+33).then((data) => {
        console.log(data.body)
        this.middle.push(data.body)
      })
      httpService.get(constants.SCHOOL_LIST+66).then((data) => {
        console.log(data.body)
        this.middle.push(data.body)
      })
    },
    getPrimary(){
      httpService.get(constants.SCHOOL_LIST+23).then((data) => {
        this.primary.push(data.body)
      })
      httpService.get(constants.SCHOOL_LIST+24).then((data) => {
        this.primary.push(data.body)
      })
      httpService.get(constants.SCHOOL_LIST+25).then((data) => {
        this.primary.push(data.body)

      })
    },
    toIntroduce(item) {//跳转到点击学校的详细介绍
      this.$router.push({path:'/schools/schoolIntroduce',query:{id:item.id}})
    }
  }
}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.myTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1vw 0;
  img{
    height: 3vw;
  }
  p{
    padding-left: 1vw;
    margin-bottom: 0;
  }
  hr{
    margin: 0 0 0 1vw;
    background-color: #2f64ac;
  }
}
.tenReason{
  padding: 4vw 0;
}
.tenReason:nth-of-type(odd){
  background-color: #f3f3f3;
}
.myContent{
  padding: 1vw 13vw;
  position: relative;
  //height: 50vh;
}
.tenReason1{
  //background-color: seashell;
}
.smallAge{
  //width: 80%;
  margin: 0 auto 2vw;
  .point{
    //position: sticky;
    //top: 30vw;
    //z-index: 999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .7vw 13vw;
    background-color: #2f64ac;
    color: white;
    height: 3vw;
  }
  a:hover{
    font-size: 18px;
  }
  .introduction{
    display: flex;
    padding: 1vw 20vw;
    align-items: center;
    .introduction-left{
      width: 10%;
    }
  }
}
.allReason{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 4vw;
}
.reason{
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  width: 18%;
  padding: 1vw;
  margin-top: 2vw;
}
.reason:nth-of-type(odd){
  background-color: #2f64ac;
  color: #faf2d5
}
.peidu{
  display: flex;
  align-items: center;
  justify-content: center;
  margin:1vw 0;
  //outline: 1px solid red;
  height: 13vw;
}
.peidu-left{
  //outline: 1px solid red;
  border-radius: 10px;
  overflow: hidden;
  height: 100%;
  width: 30%;
  //img{
  //  clip-path:polygon(25% 0%, 75% 0%,100% 50%,75% 100%,25% 100%,0% 50%);
  //}
}
.peidu-right{
  width: 40%;
  padding-left: 2vw;
  //outline: 1px solid red;
}
.address{
  display: flex;
  justify-content: space-around;
  align-items: center;
  .addressChild{
    display: flex;
    align-items: center;
    justify-content: center;
    .addressChild-img{
      height: 3vw;
      padding-right: 1vw;
    }
    div{
      font-weight: 600;
      font-size: 1.3vw;
    }
  }
}
.myH{
  font-weight: bold;
  font-size: 2vw;
}
.yI{
  background-color: rgb(239, 244, 248);
  padding: 3vw 2vw;
  width: 100%;
  margin: 0 auto;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1vw;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.yI2{
  padding: 3vw 2vw;
  width: 75%;
  margin: 0 auto;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1vw;
}
.yI3{
  padding: 3vw 2vw;
  width: 75%;
  margin: 0 auto;
  border-radius: 20px;
  font-weight: bold;
  font-size: 1vw;
  display: flex;
  justify-content: space-evenly;
}
.in{
  //display: flex;
  //justify-content: space-evenly;
  width: 90%;
  margin: 0 auto;
}
.yI-right-img{
  height: 80%;
}
.myClick{
  background-color: #2f64ac;
  color: #faf2d5;
}
body,
html {
  width: 100%;
  max-height: 100%;
  font-family: 'Montserrat', sans-serif;
  padding: 5px;
  color: white;
}
.square{
  width: 10vw;
  height: 10vw;
  margin: 20px;
  transform: rotate(45deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.square .text{
  transform: rotate(-45deg);
  font-size: .9vw;
}
.img-card{
  width: 250px;
}
.tuijian{
  font-size: 1.5vw;
  font-weight: bold;
}
.myB{
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: 0 auto;
}
.btn1{
  width: 50%;
  text-align: center;
  padding: 1vw;
  font-weight: bold;
  font-size: 1.2vw;
  border: 1px solid black;
}
 .btn1:hover{
   cursor: pointer;
 }
.liucheng{
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  height: 10vw;
}
.liucheng-son{
  margin-top: .5vw;
  width: 25%;
  padding: 1.5vw;
  text-align: center;
  border: 1px solid black;
}
.sonT{
  margin-top: .5vw;
  font-size: .8vw;
}
.xuezhi{
  text-align: center;
  margin-bottom: .5vw;
  font-size: 1vw;
}
.zhong{
  display: flex;
  justify-content: space-between;
}
.zhong-son{
  width: 32%;
  text-align: center;
  border: 1px solid black;
}
.zhongB{
  padding: 1vw;
  background-color: #2f64ac;
  color: #faf2d5;
}
.zhongT{
  font-size: .8vw;
  padding: 1vw 2vw 2vw;
  text-align: left;
  li{
    list-style: disc;
    margin-bottom: .6vw;
  }
}
</style>

<template>
  <div>
    <div class="major">
      <div class="main-title">专业方向</div>
      <div class="driver"></div>
      <div class="zhuanye">
        <div v-for="item of zhuanyeDatas" :key="item.id" @click="toSubMajors(item.name)">
          <div class="zhuanyeCard" :style="{ background: `url(${require('../../assets/myImage/zhuanyetu.png')})`,backgroundRepeat:noR,backgroundSize:cover}">{{item.name}}</div>
        </div>
      </div>
      <div class="myBtn" @click="getMore()">更多选择</div>
    </div>
    <div class="subMajor">

    </div>
  </div>
</template>

<script>
import {schoolService} from "@/services/school";
import subMajors from "@/views/subMajors";
import {httpService} from "@/services/http";
export default {
  name: "Major",
  components:{
    subMajors
  },
  data(){
    return{
      zhuanyeDatas:'',
      count:'',
      noR:'no-repeat',
      cover:'cover'
    }
  },
  created() {
    schoolService.getSubjectTypes().then((data) => {
      this.zhuanyeDatas = data.slice(0,8)
      this.count = data.length
    })
  },
  methods:{
    getMore(){
      if (this.zhuanyeDatas.length < this.count){
        schoolService.getSubjectTypes().then((data) => {
          this.zhuanyeDatas = data
          // console.log(1,this.zhuanyeDatas)
        })
      }else {
        alert('没有更多专业了')
      }
    },
    toSubMajors(main0){
      this.$router.push({path:'/project-details',query:{main0:main0,main:''}})
    }
  }
}
</script>

<style lang="scss" scoped>
//*{
//  outline: 1px solid red;
//}
.major{
  width: 85%;
  margin: 0 auto;
}
.zhuanye{

  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  .zhuanyeCard{

  margin: 0 auto 40px;
    background-size:cover;
    //background-repeat: no-repeat;
    //background-position: center;
    //width: 12vw;
    //height: 270px;

    height: 16vw;
    width: 18vw;
    color: white;
    text-align: center;
    line-height: 16vw;
    font-size: 1.4vw;
    border-radius: 20px;
    overflow: hidden;
    cursor: pointer;
  }:hover{
  text-decoration: underline;
     }
}

</style>
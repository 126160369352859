<template>
  <div class="main-container">
<!--    <heroOne/>-->
    <searchBox class="Box"></searchBox>
    <top3Skill></top3Skill>
    <Major></Major>
    <showhotspot></showhotspot>
  </div>
</template>

<script>
import data from "../data/project.json";
import Header from "@/components/Header";
import CourseList from "../components/CourseList";
import Breadcrumb from "../components/Breadcrumb";
import ProjectGrid from "../components/ProjectGrid";
import BrandCarousel from "../components/BrandCarousel";
import Footer from "../components/Footer";
import OffCanvasMobileMenu from "@/components/OffCanvasMobileMenu";
import { schoolService } from "../services/school";
import InfiniteLoading from "vue-infinite-loading";
import heroOne from "@/components/sections/HeroOne";
import searchBox from "@/components/Searchbox/searchBox";
import top3Skill from "@/components/top3/top3Skill";
import Major from "@/components/showMajor/Major";
import showhotspot from "@/components/showhotspot";
import FormTeacherGroup from "@/components/sections/FormTeacherGroup";

export default {
  components: {
    Header,
    Breadcrumb,
    ProjectGrid,
    BrandCarousel,
    Footer,
    OffCanvasMobileMenu,
    CourseList,
    InfiniteLoading,
    heroOne,
    searchBox,
    top3Skill,
    Major,
    showhotspot,
    FormTeacherGroup
  },
  data() {
    return {
      data,
      subjects: null,
      items: [
        {
          text: "Home",
          to: "/",
        },
        {
          text: "Project",
          active: true,
        },
      ],
    };
  },
  created() {
  },
  methods: {
  },
  metaInfo: {
    title: "新加坡留学网 - 专业列表",
    htmlAttrs: {
      lang: "en",
      amp: true,
    },
  },
};
</script>

